import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Grid = makeShortcode("Grid");
const Text = makeShortcode("Text");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <h1 {...{
        "id": "colour-guide"
      }}>{`Colour Guide`}</h1>
      <Grid my={4} gridTemplateColumns="10rem repeat(5, 15rem)" gridTemplateRows="1fr 10rem 10rem 10rem" gridGap={2} mdxType="Grid">
    <Text textAlign="center" mdxType="Text"></Text>
    <Text textAlign="center" mdxType="Text">DOMAINS (GREEN)</Text>
    <Text textAlign="center" mdxType="Text">WEBSITES + ALL* (BLUE)</Text>
    <Text textAlign="center" mdxType="Text">HOSTING (RED)</Text>
    <Text textAlign="center" mdxType="Text">EMAIL (PURPLE)</Text>
    <Text textAlign="center" mdxType="Text">SECURITY (RED)</Text>
    <Box bg="white" justifyContent="center" alignItems="center" mdxType="Box">
        <Text variant="giga" mdxType="Text">colourDark</Text>
    </Box>
    <Box bg="greenDark" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="white" variant="gigaBold" mdxType="Text">
            #467963
        </Text>
    </Box>
    <Box bg="blueDark" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="white" variant="gigaBold" mdxType="Text">
            #292E42
        </Text>
    </Box>
    <Box bg="redDark" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="white" variant="gigaBold" mdxType="Text">
            #6B393F
        </Text>
    </Box>
    <Box bg="purpleDark" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="white" variant="gigaBold" mdxType="Text">
            #46283F
        </Text>
    </Box>
    <Box bg="orangeDark" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="white" variant="gigaBold" mdxType="Text">
            #C4470E
        </Text>
    </Box>
    <Box bg="white" justifyContent="center" alignItems="center" mdxType="Box">
        <Text variant="giga" mdxType="Text">colour</Text>
    </Box>
    <Box bg="green" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="white" variant="gigaBold" mdxType="Text">
            #33CC99
        </Text>
    </Box>
    <Box bg="blue" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="white" variant="gigaBold" mdxType="Text">
            #3440B3
        </Text>
    </Box>
    <Box bg="red" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="white" variant="gigaBold" mdxType="Text">
            #E62E31
        </Text>
    </Box>
    <Box bg="purple" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="white" variant="gigaBold" mdxType="Text">
            #B32B85
        </Text>
    </Box>
    <Box bg="orange" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="white" variant="gigaBold" mdxType="Text">
            #FF6600
        </Text>
    </Box>
    <Box bg="white" justifyContent="center" alignItems="center" mdxType="Box">
        <Text variant="giga" mdxType="Text">colourLight</Text>
    </Box>
    <Box bg="greenLight" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="black" variant="giga" mdxType="Text">
            #DBEDD6
        </Text>
    </Box>
    <Box bg="blueLight" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="black" variant="giga" mdxType="Text">
            #EEF0FB
        </Text>
    </Box>
    <Box bg="redLight" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="black" variant="giga" mdxType="Text">
            #FFEEEE
        </Text>
    </Box>
    <Box bg="purpleLight" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="black" variant="giga" mdxType="Text">
            #E6E4F4
        </Text>
    </Box>
    <Box bg="orangeLight" justifyContent="center" alignItems="center" mdxType="Box">
        <Text color="black" variant="giga" mdxType="Text">
            #FFEBE6
        </Text>
    </Box>
      </Grid>
      <p>{`*`}{` When representing the entire brand, use blue as the overarching colour.`}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      